
import { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAuth } from "../hooks/auth";

const Home: NextPage = () => {
  const router = useRouter();
  const { walletAddress } = useAuth();

  useEffect(() => {
    router.push('/app');
  }, [walletAddress]);

  return (
    <div />
  );
};

export default Home;
